exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-dashboard-js": () => import("./../../../src/pages/data-dashboard.js" /* webpackChunkName: "component---src-pages-data-dashboard-js" */),
  "component---src-pages-datablock-collector-js": () => import("./../../../src/pages/datablock-collector.js" /* webpackChunkName: "component---src-pages-datablock-collector-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-data-dashboard-js": () => import("./../../../src/pages/es/data-dashboard.js" /* webpackChunkName: "component---src-pages-es-data-dashboard-js" */),
  "component---src-pages-es-datablock-collector-js": () => import("./../../../src/pages/es/datablock-collector.js" /* webpackChunkName: "component---src-pages-es-datablock-collector-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-legal-notice-nbk-token-js": () => import("./../../../src/pages/es/Legal-Notice-NBK-Token.js" /* webpackChunkName: "component---src-pages-es-legal-notice-nbk-token-js" */),
  "component---src-pages-es-nbk-token-js": () => import("./../../../src/pages/es/nbk-token.js" /* webpackChunkName: "component---src-pages-es-nbk-token-js" */),
  "component---src-pages-es-neuroai-business-js": () => import("./../../../src/pages/es/neuroai-business.js" /* webpackChunkName: "component---src-pages-es-neuroai-business-js" */),
  "component---src-pages-es-privacy-policy-datablock-collector-js": () => import("./../../../src/pages/es/Privacy-Policy-Datablock-Collector.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-datablock-collector-js" */),
  "component---src-pages-es-privacy-policy-js": () => import("./../../../src/pages/es/Privacy-Policy.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-js" */),
  "component---src-pages-es-terms-of-use-js": () => import("./../../../src/pages/es/Terms-of-use.js" /* webpackChunkName: "component---src-pages-es-terms-of-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-nbk-token-js": () => import("./../../../src/pages/Legal-Notice-NBK-Token.js" /* webpackChunkName: "component---src-pages-legal-notice-nbk-token-js" */),
  "component---src-pages-nbk-token-js": () => import("./../../../src/pages/nbk-token.js" /* webpackChunkName: "component---src-pages-nbk-token-js" */),
  "component---src-pages-neuroai-business-js": () => import("./../../../src/pages/neuroai-business.js" /* webpackChunkName: "component---src-pages-neuroai-business-js" */),
  "component---src-pages-privacy-policy-datablock-collector-js": () => import("./../../../src/pages/Privacy-Policy-Datablock-Collector.js" /* webpackChunkName: "component---src-pages-privacy-policy-datablock-collector-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/Privacy-Policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/Terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

